<template>
  <q-page class="q-pa-sm">
    <div class="row">
      <div class="col-8">
        <q-item>
          <q-input v-model="entity.header"
                   :rules="[val => $validators.required(val)]"
                   class="full-width"
                   clearable
                   dense
                   label="Название"
                   outlined/>
        </q-item>
      </div>
      <div class="col-4">
        <q-item>
          <q-input v-model="entity.price"
                   :rules="[val => $validators.positiveNumber(val) || 'Только положительное число']"
                   class="full-width"
                   dense
                   label="Сумма начисления"
                   outlined
                   type="number"
          >
            <template v-slot:prepend>
              <q-icon name="currency_ruble"/>
            </template>
          </q-input>
        </q-item>
      </div>
      <div class="col-12">
        <q-item>
          <q-input v-model="entity.summary" :rules="[val => $validators.required(val)]"
                   class="full-width" clearable
                   dense
                   label="Краткое описание"
                   outlined/>
        </q-item>
      </div>
      <div class="col-12">
        <q-item>
          <q-input v-model="entity.description" autogrow class="full-width" clearable dense
                   label="Описание"
                   outlined/>
        </q-item>
      </div>
      <div class="col-12">
        <q-card bordered class="q-ma-md q-pa-md" flat>
          <span v-if="!entity.opportunity_start">
            Даты не указаны
          </span>
          <span v-else>
          Даты {{
              $filters.toHumanDate(entity.opportunity_start)
            }} -   {{ $filters.toHumanDate(entity.opportunity_end) }}
            </span>
          <q-btn class="q-ml-lg shadow-5" icon="edit" round size="sm">
            <q-popup-proxy cover transition-hide="scale" transition-show="scale"
                           @before-show="updateDateRange">
              <q-date v-model="dtRange" range>
                <div class="row items-center justify-end q-gutter-sm">
                  <q-btn v-close-popup color="primary" flat label="Отменить"/>
                  <q-btn v-close-popup color="primary" flat label="OK" @click="saveDateRange"/>
                </div>
              </q-date>
            </q-popup-proxy>
          </q-btn>
        </q-card>
      </div>

      <div v-if="entityId" class="col-4">
        <q-item>
          <image-uploader v-model="entity.logo_url"
                          :uploadMethod="file => new OpportunityApi().opportunityControllerUploadImage(file, entityId || 1)"
                          class="full-width"
                          label="Лого (PNG)"/>
        </q-item>
      </div>

      <div v-if="entityId" class="col-8">
        <q-item>
          <image-uploader v-model="entity.poster_url"
                          :uploadMethod="file => new OpportunityApi().opportunityControllerUploadImage(file, entityId || 1)"
                          class="full-width"
                          label="Постер (PNG)"/>
        </q-item>
      </div>

      <links-form v-model="entity.links"></links-form>
    </div>

    <div class="q-pa-md">
      <q-btn :loading="loading" class="q-mr-md"
             color="primary"
             icon="save"
             label="Сохранить"
             rounded
             @click="saveEntity"/>

      <q-btn class="q-mr-md"
             icon="cancel"
             label="Назад"
             rounded
             @click="$router.back()"/>
    </div>
  </q-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import LinksForm from '@/components/forms/linksForm';
import { useRoute } from 'vue-router';
import { OpportunityApi } from '@/api/apis/opportunity-api.ts';
import { Notify } from 'quasar';
import ImageUploader from '@/components/forms/imageUploader';

export default defineComponent({
  components: {
    LinksForm,
    ImageUploader,
  },
  setup() {
    const route = useRoute();
    const entityId = ref(route.params.id);
    const entity = ref({
      header: '',
      summary: '',
      description: '',
      logo_url: '',
      poster_url: '',
      links: [],
      price: '1',
      status: 'draft',
      opportunity_start: null,
      opportunity_end: null,
    });

    if (entityId.value) {
      new OpportunityApi().opportunityControllerGetOpportunity(entityId.value)
        .then(({ data }) => {
          entity.value = data;
        })
        .catch(() => entityId.value = 0);
    }

    const dtRange = ref({
      from: '2020/07/08',
      to: '2020/07/17',
    });

    const loading = ref(false);
    return {
      OpportunityApi,
      entity,
      entityId,
      dtRange,
      loading,

      updateDateRange() {
        dtRange.value = {
          from: entity.value.opportunity_start,
          to: entity.value.opportunity_end,
        };
      },

      saveDateRange() {
        entity.value.opportunity_start = new Date(dtRange.value.from).toISOString();
        entity.value.opportunity_end = new Date(dtRange.value.to).toISOString();
      },

      async saveEntity() {
        loading.value = true;
        try {
          delete entity.value.opportunity_id;

          if (entityId.value) {
            await new OpportunityApi().opportunityControllerUpdateOpportunity(entity.value, entityId.value);
          } else {
            const { data } = await new OpportunityApi().opportunityControllerCreateOpportunity(entity.value);
            if (data?.opportunity_id) {
              entityId.value = data?.opportunity_id;
            }
          }

          Notify.create({
            type: 'positive',
            message: 'Сохранено',
          });
        } catch (e) {
          console.error(e);
        }

        loading.value = false;
      },
    };
  },
});
</script>

<style>

</style>
